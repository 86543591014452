import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ContrastContext from '../context/contrastContext';
import Hero from '../components/Hero';
import { EyeIcon, FlagIcon, ScaleIcon } from '../components/Icon';
import Section from '../components/Section';
import { MarkdownContent } from '../components/Content';

const AboutPage = ({
  title,
  heroImage,
  heroContent,
  advisors,
  funders,
  partners,
}) => {
  return (
    <>
      <Hero image={heroImage} />
      <Section colorScheme="black">
        <div className="container flex flex-col md:flex-row py-12 gap-x-12 text-base">
          <div className="pb-4 md:w-1/3">
            <h2>
              <span className="bg-theme-underline">{title}</span>
            </h2>
          </div>
          <div className="md:w-2/3 text-base">
            <MarkdownContent content={heroContent} />
          </div>
        </div>
      </Section>
      <Section colorScheme="white" overlaySide="left" overlayLength="large">
        <ContrastContext.Consumer>
          {(context) => (
            <div className="container flex flex-col py-4 xl:flex-row">
              <div className="py-8 text-center border-b-2 border-gray-300 xl:px-8 xl:border-b-0 xl:border-r-2">
                <div
                  className={`inline-block ${
                    context.isHighContrastEnabled
                      ? 'bg-primaryHC'
                      : 'bg-primary'
                  } p-2 rounded-full mb-2`}
                >
                  <ScaleIcon className="w-16 h-16 text-white" />
                </div>
                <h2 className="mb-2">Neutrality</h2>
                <p className="text-base">
                  GDI is non-political, nonpartisan, and global in nature. We
                  operate and partner in dozens of countries and with a variety
                  of organisations, all united in combating disinformation and
                  its harms.
                </p>
              </div>
              <div className="py-8 text-center border-b-2 border-gray-300 xl:px-8 xl:border-b-0 xl:border-r-2">
                <div
                  className={`inline-block ${
                    context.isHighContrastEnabled
                      ? 'bg-primaryHC'
                      : 'bg-primary'
                  } p-2 rounded-full mb-2`}
                >
                  <FlagIcon className="w-16 h-16 text-white" />
                </div>
                <h2>Independence</h2>
                <p className="text-base">
                  GDI does not take funding from media companies or grants from
                  technology companies. Our funding comes from a wide range of
                  sources including governments, foundations and licencing our
                  data to those who can use it to defund disinformation.
                </p>
              </div>
              <div className="py-8 text-center xl:px-8">
                <div
                  className={`inline-block ${
                    context.isHighContrastEnabled
                      ? 'bg-primaryHC'
                      : 'bg-primary'
                  } p-2 rounded-full mb-2`}
                >
                  <EyeIcon className="w-16 h-16 text-white" />
                </div>
                <h2>Transparency</h2>
                <p className="text-base">
                  Many of our research methodologies and findings are made
                  public, as are our funding sources. We also operate an open
                  appeals process for sites that wish to contest their risk
                  assessment.
                </p>
              </div>
            </div>
          )}
        </ContrastContext.Consumer>
      </Section>
      {advisors && advisors.length && (
        <Section colorScheme="grey" overlaySide="left">
          <div className="container py-12 text-base">
            <h2 className="mb-8">
              <span className="bg-theme-underline">Advisory Panel</span>
            </h2>
            <div className="grid md:grid-cols-3 items-center justify-between gap-12">
              {advisors.map(({ name, organization, description }) => (
                <div key={name}>
                  <span className="block text-xl font-bold">{name}</span>
                  {organization && (
                    <span className="block text-xl mb-2">{organization}</span>
                  )}
                  <p className="mt-2">{description}</p>
                </div>
              ))}
            </div>
          </div>
        </Section>
      )}
      {funders && funders.length && (
        <Section colorScheme="white" overlaySide="left">
          <div className="container py-12  text-base">
            <h2 className="mb-8">
              <span className="bg-theme-underline">Select Past and Present Funders</span>
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 justify-items-center justify-between gap-12">
              {funders.map(({ image, alt }) => (
                <GatsbyImage
                  image={getImage(image)}
                  alt={alt}
                  objectFit="contain"
                  key={alt}
                />
              ))}
            </div>
          </div>
        </Section>
      )}
      {partners && partners.length && (
        <Section colorScheme="white" overlaySide="left">
          <div className="container py-12  text-base">
            <h2 className="mb-8">
              <span className="bg-theme-underline">Select Past and Present Partners</span>
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 justify-items-center justify-between gap-12">
              {partners.map(({ image, alt }) => (
                <GatsbyImage
                  image={getImage(image)}
                  alt={alt}
                  objectFit="contain"
                  key={alt}
                />
              ))}
            </div>
          </div>
        </Section>
      )}
    </>
  );
};

AboutPage.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.object.isRequired,
  heroContent: PropTypes.string.isRequired,
  advisors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      organization: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  funders: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object.isRequired,
      alt: PropTypes.string,
    })
  ),
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object.isRequired,
      alt: PropTypes.string,
    })
  ),
};

export default AboutPage;
